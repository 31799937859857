import { FC } from 'react'
import { Box, CircularProgress } from '@mui/material'

type Props = {
  disableWrap?: boolean
}

const Loader: FC<Props> = ({ disableWrap }) => {
  if (disableWrap) {
    return (
      <CircularProgress color='inherit'/>
    )
  }

  return (
    <Box sx={{
      height: '100%',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#eee',
    }}>
      <CircularProgress color='inherit'/>
    </Box>
  )
}

export default Loader
