import { create } from 'zustand'

let increment = 0

type Variant = 'info' | 'success' | 'error'

type State = {
  messages: {
    id: number
    text: string
    variant: Variant
  }[]
}
type Actions = {
  close: (id: number) => void
  showError: (err: any) => void
  showSuccess: (text: string) => void
  showInfo: (text: string) => void
}
const initialState: State = {
  messages: [],
}

export const useToastsStore = create<State & Actions>((set, get) => {
  const addMessage = (text: string, variant: Variant) => set({
    messages: [
      ...get().messages,
      {
        id: ++increment,
        text,
        variant,
      },
    ]
  })

  return {
    ...initialState,

    close: (id) => {
      set({
        messages: get().messages.filter((m) => m.id !== id)
      })
    },

    showError: (err) => {
      console.log('err', err)
      let text = 'Error'
      if (typeof err === 'string') {
        text = err
      }
      else if (err.response?.data?.message) {
        text = err.response.data.message
      }
      else if (err.response?.data?.Message) {
        text = err.response.data.Message
      }
      else if (err.message) {
        text = err.message
      }
      addMessage(text, 'error')
    },

    showInfo: (text) => addMessage(text, 'info'),
    showSuccess: (text) => addMessage(text, 'success'),
  }
})
