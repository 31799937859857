import { FC, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import api from '../api'
import { useAuthStore } from './authStore'

const ResponseInterceptor: FC = () => {
  const navigate = useNavigate()
  const logout = useAuthStore((s) => s.logout)

  const interceptorId = useRef<number | null>(null)

  useEffect(() => {
    interceptorId.current = api.interceptors.response.use(
      (res) => res,
      (error) => {
        if (error.response?.status === 401) {
          logout(navigate)
        }
        return Promise.reject(error)
      }
    )

    return () => {
      api.interceptors.response.eject(interceptorId.current as number)
    }
  }, [navigate, logout])

  return null
}

export default ResponseInterceptor
