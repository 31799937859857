import { createTheme } from '@mui/material'

declare module '@mui/material/styles' {
  interface Theme {
    app: typeof app
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    app?: Partial<typeof app>
  }
}

const { spacing, shadows } = createTheme()

const app = {
  violet: '#8E55EA',
  bgGrey: '#F3F5F7',
  bgBody: '#141718',
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0084FF',
    },
    secondary: {
      main: '#3FDD78',
    },
    error: {
      main: '#D84C10',
    },
    warning: {
      main: '#FEAB49',
    },
    success: {
      main: '#52BA69',
    },
    text: {
      disabled: '#6C7275',
    },
  },
  app,
  shape: {
    borderRadius: 12,
  },

  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
          paddingLeft: spacing(6),
          paddingRight: spacing(6),
          flex: 1,
          minHeight: 0,
          textTransform: 'none',
        },
      },
    },
    MuiTabs: {
      defaultProps: {
        centered: true,
      },
      styleOverrides: {
        root: {
          minHeight: 0,
          background: '#F3F5F7',
          borderRadius: spacing(1.5),
          padding: spacing(0.5),
          overflow: 'visible',
          '& .Mui-selected': {
            color: '#232627',
            background: '#FEFEFE',
            borderRadius: '10px',
            boxShadow: shadows[2],
          },
        },
        indicator: {
          display: 'none',
        },
        scroller: {
          overflow: 'visible !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#F3F5F7',
        },
        root: {
          '&:not(.Mui-focused):not(:hover)': {
            background: '#F3F5F7',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        button: {
          textTransform: 'none',
        },
      },
    }
  },
})

export default theme
