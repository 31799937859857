import { Alert, Slide, Snackbar } from '@mui/material'

import { useToastsStore } from './useToastsStore'

const ToastsLayout = () => {
  const close = useToastsStore((s) => s.close)
  const messages = useToastsStore((s) => s.messages)

  return (
    <>
      {messages.map(({ id, text, variant }, i) => (
        <Snackbar
          key={id}
          open
          autoHideDuration={6000}
          onClose={() => close(id)}
          anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
          TransitionComponent={Slide}
          sx={{
            top: `${8*3+(7*8)*i}px !important`,
          }}
        >
          <Alert
            onClose={() => close(id)}
            severity={variant}
            variant='filled'
            sx={{ width: '100%' }}
          >
            {text}
          </Alert>
        </Snackbar>
      ))}
    </>
  )
}

export default ToastsLayout
