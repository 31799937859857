import React from 'react'
import ReactDOM from 'react-dom/client'
import { CssBaseline, GlobalStyles, ThemeProvider } from '@mui/material'
import { BrowserRouter } from 'react-router-dom'

import App from './App'
import reportWebVitals from './reportWebVitals'
import theme from './theme'
import ResponseInterceptor from './auth/ResponseInterceptor'
import ToastsLayout from './layout/toasts/ToastsLayout'

const inputGlobalStyles = (
  <GlobalStyles
    styles={{
      'html, body': {
        height: '100vh',
        background: '#141718',
      },
      '#root': {
        width: '100%',
        height: '100%',
        position: 'fixed',
        display: 'flex',
        justifyContent: 'center',
      },
    }}
  />
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)
root.render(
  // <React.StrictMode>
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline />
    {inputGlobalStyles}
    <BrowserRouter>
      <ResponseInterceptor/>
      <ToastsLayout/>
      <App />
    </BrowserRouter>
  </ThemeProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
