import { create } from 'zustand'
import { NavigateFunction } from 'react-router-dom'

import { User } from './authTypes'
import api from '../api'

type State = {
  isLoading: boolean
  token: null | string
  user: null | User
}
type Actions = {
  setIsLoading: (isLoading: boolean) => void
  login: (data: { email: string, password: string }) => void
  fetchUser: () => Promise<User>
  createUser: (data: { name: string, email: string, password: string }) => void
  updateUser: (data: { name: string, password: string }) => Promise<void>
  confirmEmail: (data: { email: string, code: string }) => void
  resetPasswordEmail: (data: { email: string }) => void
  resetPassword: (data: { email: string, reset_code: string, password: string }) => void
  logout: (navigate: NavigateFunction) => void
}

const initialState: State = {
  isLoading: true,
  user: null,
  token: null,
}

export const useAuthStore = create<State & Actions>((set) => ({
  ...initialState,
  token: window.localStorage.getItem('_auth'),

  setIsLoading: (isLoading) => {
    set({ isLoading })
  },

  fetchUser: async () => {
    const { data } = await api.get<User>('/user')
    set({ user: data })
    return data
  },
  updateUser: async (userData) => {
    const { data } = await api.put<User>('/user', userData)
    set({ user: data })
  },
  createUser: async (userData) => {
    await api.post('/user', {
      email: userData.email.trim().toLowerCase(),
      name: userData.name.trim(),
      password: userData.password.trim(),
    })
  },
  confirmEmail: async (confirmData) => {
    await api.post('/user/confirm-email', {
      email: confirmData.email.trim().toLowerCase(),
      code: confirmData.code.trim(),
    })
  },
  resetPasswordEmail: async (data) => {
    await api.post('/user/forgot-password', data)
  },
  resetPassword: async (data) => {
    await api.post('/user/reset-password', data)
  },

  login: async (userData) => {
    const { data } = await api.post<{ access_token: string }>('/user/auth', {
      email: userData.email.trim().toLowerCase(),
      password: userData.password.trim(),
    })
    const token = data.access_token
    set({ token })
    window.localStorage.setItem('_auth', token)
  },
  logout: (navigate) => {
    set({ token: null, user: null, isLoading: true })
    window.localStorage.removeItem('_auth')
    navigate('/login')
  },
}))
