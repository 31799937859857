import api from '../api'
import { AuthorizeData } from './paymentTypes'

export const paymentInfoRoute = '/payment-info'

export const getBillingProfile = () => {
  return api.get<
    Pick<AuthorizeData, 'billingProfileId' | 'hasPaymentProfile' | 'paymentProfileId'>
  >('/user/billing-profile')
}
