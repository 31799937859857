import React, { Suspense, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import PrivateRoute from './auth/PrivateRoute'
import { paymentInfoRoute } from './paymentInfo/paymentInfo'
// import AuthProvider from './auth/AuthProvider'

const getTaskDetails = () => import('./tasks/details/TasksDetailsLayout')

const UserLayout = React.lazy(() => import('./user/UserLayout'))
const PaymentInfoLayout = React.lazy(() => import('./paymentInfo/PaymentInfoLayout'))
const InvoiceDetailsLayout = React.lazy(() => import('./invoices/details/InvoiceDetailsLayout'))
const TasksDetailsLayout = React.lazy(getTaskDetails)
const CreateTaskLayout = React.lazy(() => import('./tasks/create/CreateTaskLayout'))
const HistoryLayout = React.lazy(() => import('./history/HistoryLayout'))
const SettingsLayout = React.lazy(() => import('./settings/SettingsLayout'))
const LoginPage = React.lazy(() => import('./login/LoginAndRegisterPage'))
const ForgotPasswordPage = React.lazy(() => import('./login/ForgotPasswordPage'))
const ResetPasswordPage = React.lazy(() => import('./login/ResetPasswordPage'))
const ConfirmEmailPage = React.lazy(() => import('./login/ConfirmEmailPage'))

console.log('process.env=', process.env)

function App() {
  useEffect(() => {
    getTaskDetails()
  }, [])

  return (
    <Routes>
      <Route
        path='/'
        element={
          <PrivateRoute>
            <Suspense>
              <CreateTaskLayout />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path='/tasks/:taskId'
        element={
          <PrivateRoute>
            <Suspense>
              <TasksDetailsLayout />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path='/history'
        element={
          <PrivateRoute>
            <Suspense>
              <HistoryLayout />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path='/user'
        element={
          <PrivateRoute>
            <Suspense>
              <UserLayout />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path={paymentInfoRoute}
        element={
          <PrivateRoute>
            <Suspense>
              <PaymentInfoLayout />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path='/settings'
        element={
          <PrivateRoute>
            <Suspense>
              <SettingsLayout />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route
        path='/invoices/:invoiceId'
        element={
          <PrivateRoute>
            <Suspense>
              <InvoiceDetailsLayout />
            </Suspense>
          </PrivateRoute>
        }
      />
      <Route path='/login' element={<Suspense><LoginPage /></Suspense>} />
      <Route path='/register' element={<Suspense><LoginPage /></Suspense>} />
      <Route path='/confirm-email' element={<Suspense><ConfirmEmailPage /></Suspense>} />
      <Route path='/forgot-password' element={<Suspense><ForgotPasswordPage /></Suspense>} />
      <Route path='/reset-password' element={<Suspense><ResetPasswordPage /></Suspense>} />
    </Routes>
  )
}

export default App
