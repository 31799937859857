import axios from 'axios'

import { useAuthStore } from './auth/authStore'

export const isProduction = process.env.REACT_APP_AZURE_ENV === 'production'

const baseURL = isProduction
  ? 'https://mateemli-prod-ai-tool-api.azurewebsites.net/api/'
  : 'https://mateemli-prod-ai-tool-api-staging.azurewebsites.net/api/'

const api = axios.create({
  baseURL,
})

api.defaults.withCredentials = true
api.interceptors.request.use(
  (config) => {
    const token = useAuthStore.getState().token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  }
)
// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     if (error.response.status === 401) {
//       localStorage.clear()
//       window.location.href = '/login'
//     }
//   }
// )

export default api
