import { useNavigate } from 'react-router-dom'
import { FC, ReactNode, useEffect } from 'react'

import { useAuthStore } from './authStore'
import Loader from '../components/Loader'

type Props = {
  children: ReactNode
}

const PrivateRoute: FC<Props> = ({ children }) => {
  const token = useAuthStore((s) => s.token)
  const user = useAuthStore((s) => s.user)
  const fetchUser = useAuthStore((s) => s.fetchUser)
  const isLoading = useAuthStore((s) => s.isLoading)
  const setIsLoading = useAuthStore((s) => s.setIsLoading)
  const logout = useAuthStore((s) => s.logout)
  const navigate = useNavigate()

  useEffect(() => {
    if (token) return
    logout(navigate)
  }, [logout, navigate, token])

  useEffect(() => {
    if (!token || user) return
    ;(async () => {
      try {
        await fetchUser()
      }
      catch (err) {
        logout(navigate)
      }
      setIsLoading(false)
    })()
  }, [fetchUser, setIsLoading, user, token, logout, navigate])

  if (isLoading || !user) {
    return (
      <Loader/>
    )
  }

  return (
    <>
      {children}
    </>
  )
}

export default PrivateRoute
